.container {
    width: 100%;
    max-width: 1268px;
    margin: 0 auto;
    padding: 0 42px;
}

@media screen and (max-width: 1400px) {
    .container {
        max-width: 1084px;
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding: 0 20px;
    }
}
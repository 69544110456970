.header-products {
    display: flex;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 992px) {
    .header-products {
        height: 66px;
    }
}

@media screen and (max-width: 768px) {
    .header-products {
        border-top: 1px solid var(--line);
    }  
}
.section-title {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 50px;
    line-height: 1.2;
}

@media screen and (max-width: 992px) {
    .section-title {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 768px) {
    .section-title {
        font-size: 24px;
        margin-bottom: 16px;
    }
}
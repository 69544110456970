.footer {
    margin-top: auto;
    padding: 50px 0;
    background: var(--main);
}

.footer-wrap {    
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.footer .nav-item {
    padding: 10px 0;
}

.footer .nav-link {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
}

.footer .contacts-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer .email {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 20px;
}

.footer .phone {
    font-size: 22px;
    font-weight: 800;
    color: #ffffff;
}

@media screen and (max-width: 768px) {
    .footer {
        padding: 30px 0;
    }
    .footer-wrap {
        display: block;
    }
    .footer .contacts-list {
        align-items: flex-start;
        margin-top: 8px;
    }
}
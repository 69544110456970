.header-center .nav {
    height: 74px;
}
.header-center .nav-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    height: 100%;
    border-bottom: 1px solid var(--line);
}
.header-center .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-center .nav-link {
    font-size: 18px;
    font-weight: 700;
    color: var(--main);
    transition: 0.25s;
}

@media screen and (min-width: 993px) {
    .header-center .nav-link:hover {
        color: var(--orange);
    }
}

@media screen and (max-width: 1400px) {
    .header-center .nav {
        height: 49px;
    }    
}

@media screen and (max-width: 1200px) {
    .header-center .nav-link {
        font-size: 16px;
    }    
}
@media screen and (max-width: 992px) {
    .header-center .nav {
        display: none;
    }
}
.header-search {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.header-search-input {
    height: 100%;
    border: none;
    outline: none;
    flex: auto;
    padding: 10px 10px 10px 30px;
    font-size: 16px;
    font-weight: 700;
}

.header-search-input::placeholder {
    font-size: 16px;
    font-weight: 700;
}

.header-search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid var(--line);
    z-index: 150;
    max-height: 450px;
    overflow-y: auto;
    padding: 0 1rem;
}

.header-search-results-link {
    display: block;
    padding: 1rem 0;
    border-bottom: 1px solid var(--line);
    font-weight: 500;
    color: var(--main);
    transition: .25s all ease-in-out;
}

.header-search-results-link:last-of-type {
    border-bottom: none;
}

.header-search-clear {
    height: 100%;
    width: 70px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-search-clear span { 
    position: relative;   
    width: 13px;
    height: 1px;
    background: transparent;
}

.header-search-clear span::before {
    position: absolute;
    content: '';
    top: 0px;
    left: 0;
    width: 13px;
    height: 1px;
    background: #000;
    transform: rotate(45deg);
}

.header-search-clear span::after {
    position: absolute;
    content: '';
    bottom: 0px;
    left: 0;
    width: 13px;
    height: 1px;
    background: #000;
    transform: rotate(-45deg);
}

@media screen and (max-width: 1200px) {
    .header-search-clear {
        width: 48px;
    }
}

@media screen and (max-width: 992px) {
    .header-search-submit {
        width: 66px;
    }
}

@media screen and (max-width: 768px) {
    .header-search-input {
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
    }
    .header-search-input::placeholder {
        font-size: 14px;
        font-weight: 400;
    } 
}

@media screen and (min-width: 769px) {
    .header-search-results-link:hover {
        color: var(--orange);
    }
}
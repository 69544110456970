.header-right {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    width: 360px;
    min-width: 360px;
    border-left: 1px solid var(--line);
}

@media screen and (max-width: 1400px) {
    .header-right {
        width: 200px;
        min-width: 200px;
    }    
}

@media screen and (max-width: 992px) {
    .header-right {
        display: block;
        height: 66px;
        width: 132px;
        min-width: 132px;
    }
}
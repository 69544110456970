.header-cart {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.header-cart::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main);
    transition: .25s ease-in-out;
    z-index: 2;
    transform: translateX(-101%);
}

.header-cart-wrap {
    position: relative;
    width: 30px;
    height: 30px;
    z-index: 3;
}

.header-cart-wrap .count {
    position: absolute;
    right: -4px;
    top: -4px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    transition: .25s ease-in-out;
}

.header-cart-icon {
    height: 100%;
    stroke: #000000;    
    transition: .25s ease-in-out;
}

@media screen and (min-width: 993px) {
    .header-cart:hover::before {
        transform: translateX(0);
    }
    .header-cart:hover .header-cart-wrap .count {
        color: #ffffff;
    }    
    .header-cart:hover .header-cart-icon {
        stroke: #ffffff;
    }    
}

.catalog-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    border-left: 1px solid var(--line);
    border-right: 1px solid var(--line);
}

@media screen and (max-width: 992px) {
    .catalog-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 576px) {
    .catalog-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
.header-center {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 992px) {
    .header-center {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .header-center {
        order: 1;
    }  
}
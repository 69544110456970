.catalog-categories {
    position: relative;
    width: 100%;
    padding-bottom: 100%;    
}

.catalog-categories-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid var(--line);
    border-bottom: 1px solid var(--line);
    transition: .25s ease-in-out;
    cursor: pointer;
    padding: 40px 20px 20px;
}

.catalog-categories:nth-child(4n) {
    border-right: none;
}

.catalog-categories-name {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #000000;
    transition: .25s ease-in-out;
}

.catalog-categories-img {
    width: 100%;
    max-width: 200px;
    transition: .25s ease-in-out;
}

@media screen and (min-width: 993px) {
    .catalog-categories:hover {
        box-shadow: 0 2px 20px rgba(0, 85, 141, .2);
    }

    .catalog-categories:hover .catalog-categories-name {
        /* font-size: 26px; */
        transform: scale(1.1);
        color: var(--orange);
    }

    .catalog-categories:hover .catalog-categories-img {
        transform: scale(.8);
    }
}

@media screen and (max-width: 1400px) {
    .catalog-categories-img {
        max-width: 80%;
    }
}

@media screen and (max-width: 992px) {    
    .catalog-categories:nth-child(3n) {
        border-right: none;
    }
    .catalog-categories:nth-child(4n) {
        border-right: 1px solid var(--line);
    }
}

@media screen and (max-width: 768px) {
    .catalog-categories-name {
        font-size: 18px;
    }
}

@media screen and (max-width: 576px) {
    .catalog-categories-inner {
        padding: 30px 14px 14px;
    }
    /* .catalog-categories:nth-child(2n) {
        border-right: none;
    }
    .catalog-categories {
        border-right: 1px solid var(--line);
    } */
}
.header-right .contacts-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 74px;
    border-bottom: 1px solid var(--line);
    padding: 10px;
}

.header-right .email {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1;
    color: #000000;
    transition: .25s ease-in-out;
}

.header-right .phone {
    font-size: 24px;
    font-weight: 800;
    line-height: 1.1;
    color: #000000;
    transition: .25s ease-in-out;
}

@media screen and (min-width: 993px) {
    .header-right .email:hover,
    .header-right .phone:hover {
        color: var(--orange);
    }   
}

@media screen and (max-width: 1400px) {

    .header-right .contacts-list {
        height: 49px;
        padding: 6px;
    }
    .header-right .email {
        font-size: 16px;
    }
    .header-right .phone {
        font-size: 20px;
    }   
}

@media screen and (max-width: 992px) {
    .header-right .contacts-list {
        display: none;
    }
}